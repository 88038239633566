import React from "react"

import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/seo"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const InformationPage = ({data}) => {
  const query = data.allContentfulInformation.edges[0].node;
  // const [windowHeight, setWindowHeight] = useState('100vh')

  // const handleSetWindowHeight = () => {
  //   setWindowHeight(window.innerHeight)
  // }

  // useEffect(() => {
  //   handleSetWindowHeight()
  //   window.addEventListener('resize', handleSetWindowHeight)

  //   return () => {
  //     window.removeEventListener("resize", handleSetWindowHeight);
  //   }
  // })


  return (
    <Layout className='infoLayout landingLayout' style={{ height: '100%' }}>
      <SEO title="Information" />
      <div id='mainContent'>
        <Section className='landingSection'>
          <h2>Isaac Daniels</h2>
          <article>
            <h3>About</h3>
            {documentToReactComponents(query.about.json)}
          </article>

          <article>
            <h3>Social</h3>
            <ul>
              <li>
                <a 
                  href={query.instagram}
                  rel='noopenner noreferrer'
                  target='blank'
                >Instagram</a>
              </li>
              <li>
                <a 
                  href={query.vsco}
                  rel='noopenner noreferrer'
                  target='blank'
                >Images</a>
              </li>
              <li>
                <a 
                  href={query.tumblr}
                  rel='noopenner noreferrer'
                  target='blank'
                >Inspiration</a>
              </li>
            </ul>
          </article>

          <article className='colophonSection'>
            <h3>Colophon</h3>
            {documentToReactComponents(query.colophon.json)}
          </article>
        </Section>

      </div>
      <div>
        <Section className='mediaSection'>
          <picture>
            <source srcSet={query.media.fluid.srcSet} />
            <source srcSet={query.media.fluid.srcSetWebp} />
            <img
              alt='Isaac Daniels'
              loading='lazy'
              src={query.media.fluid.src}
            />
          </picture>
        </Section>
      </div>

    </Layout>
  )
}

export const query = graphql`
  query InfoQuery {
    allContentfulInformation {
      edges {
        node {
          id
          instagram
          tumblr
          vsco
          about {
            id
            json
          }
          colophon {
            id
            json
          }
          media {
            id       
            fluid(maxWidth: 1500) {
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
            }  
            file {
              url
              fileName
              contentType
            }
          }
        }
      }
    }
  }
`

export default InformationPage
